<template>
  <div class="">
    <!--    <BackButton />-->
    <TitleHeader>ML Operations | Extracted Page Entities</TitleHeader>
    <DocCompare />
  </div>
</template>
<script>
import DocCompare from "@/components/ml-operations/DocCompare";
export default {
  name: "MLExtractedPages",
  data: () => ({}),
  components: {
    DocCompare
  }
};
</script>
<style lang="scss" scoped></style>
