<template>
  <div class="row">
    <div class="w-100 d-flex justify-content-center">
      <div class="col-sm-12 col-md-6 fixed-width">
        <IFrame :source="source" />
        <EntityPlacement />
      </div>
      <div class="col-sm-12 col-md-4">
        <MLText />
      </div>
    </div>
  </div>
</template>
<script>
import IFrame from "@/components/ml-operations/ml-doc-interface/IFrame";
import EntityPlacement from "@/components/ml-operations/ml-doc-interface/EntityPlacement";
import MLText from "@/components/ml-operations/MLText";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "DocCompare",
  components: {
    IFrame,
    EntityPlacement,
    MLText
  },
  data: () => ({
    sourcePDF: null
  }),
  computed: {
    ...mapGetters([
      "getApiUrl",
      "getPDFPage",
      "getExtractedText",
      "getEmptyValueArray",
      "getApplicationExtractedText"
    ]),
    pageDropdown() {
      let pageArr = [];
      for (let i in this.getApplicationExtractedText) {
        let item = this.getApplicationExtractedText[i];
        pageArr.push({
          id: item.uuid,
          name: parseInt(item.page_number) + 1
        });
      }
      return pageArr;
    },
    source() {
      return this.sourcePDF;
    }
  },
  methods: {
    ...mapMutations([
      "setLoading",
      "setMLTextEntities",
      "setEmptyValueArray",
      "setMLTextUuid"
    ]),
    ...mapActions(["getAWSFile", "removeLocalFile", "getMLData"]),
    handleSelected(value) {
      this.loadData(value);
    },
    handleBack() {
      this.$emit("back", "DocList");
    },
    async loadData(uuid = null) {
      this.setLoading({
        bool: true,
        message: "Processing..."
      });
      let awsResponse = await this.getAWSFile().catch(err => console.log(err));
      let mlResponse = await this.getMLData(
        uuid || this.getExtractedText.uuid
      ).catch(err => console.log(err));

      this.sourcePDF = `${this.getApiUrl}${awsResponse.data.response}?#zoom=1.008&page=${mlResponse.data.data.page_number}&toolbar=0&navpanes=0&scrollbar=0`;

      this.setMLTextEntities(mlResponse.data.data.entities[0]);
      this.setEmptyValueArray(this.getApplicationExtractedText[0].entities[0]);

      this.setLoading({ bool: false });
    }
  },
  async beforeMount() {
    this.loadData();
  },
  beforeUnmount() {
    this.removeLocalFile();
  }
};
</script>
<style lang="scss" scoped>
.fixed-width {
  min-width: 800px;
  max-width: 800px;
  min-height: calc(800px * 1.36);
  max-height: calc(800px * 1.36);
}
</style>
