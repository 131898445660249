<template>
  <div class="card mb-5">
    <Close @close="handleClose" />
    <div class="card-body text-left pt-5">
      <p>
        <strong>Entity: </strong>{{ selectedEntityName }} <br />
        <strong>Location Name: </strong>{{ selectedEntityLocationName }} <br />
        <strong
          >Confidence:
          <span
            :style="{
              fontSize: '20px',
              color: getGreenToRed(selectedEntityConfidence)
            }"
            >{{ selectedEntityConfidence || "0" }}%
          </span></strong
        >
      </p>
      <Form
        class="w-100 text-left p-0"
        @submit="handleEdit"
        :validation-schema="schema"
      >
        <TextInput
          name="ml_text"
          type="text"
          label="Edit selected text"
          placeholder=""
          class="pr-1"
          :value="selectedEntityValue"
        />
        <p>
          <input type="checkbox" :checked="selected" value="1" /> Is this the
          correct value for this entitiy?
        </p>
        <button class="fabric-btn fabric-btn-submit">Update</button>
      </Form>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";

export default {
  name: "MLTextEdit",
  props: {
    selectedEntity: {
      type: Object,
      default: null
    },
    selectedEntityIndex: {
      type: [Number, String],
      default: null
    }
  },
  data: () => ({}),
  setup() {
    const schema = Yup.object().shape({
      ml_text: Yup.string().required("required")
    });
    return {
      schema
    };
  },
  computed: {
    selectedEntityValue() {
      return this.selectedEntity?.value;
    },
    selectedEntityName() {
      return this.selectedEntityIndex;
    },
    selectedEntityConfidence() {
      return parseInt(this.selectedEntity?.confidence);
    },
    selectedEntityLocationName() {
      return this.selectedEntityIndex;
    },
    selected() {
      return this.selectedEntity?.selected;
    }
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    async handleEdit(params) {
      this.$emit("entity-updated", {
        value: params.ml_text,
        selectedEntity: this.selectedEntity
      });
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss" scoped></style>
