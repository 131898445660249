<template>
  <div class="extracted-text-wrap">
    <h4>Extracted Text</h4>
    <MLTextEdit
      v-if="getMLSelectedTextEntities !== null"
      :selected-entity="getMLSelectedTextEntities"
      :selected-entity-index="getMLSelectedTextEntitiesIndex"
      @entity-updated="handleUpdate"
      @close="handleClose"
    />
    <div v-if="getMLTextEntitiesLength > 0">
      <div class="card">
        <div class="card-body text-left">
          <div
            v-for="(entity, index) in combinedMLText"
            :class="['highlighted-text', selectedStyle(index)]"
            :key="index"
            :ref="index"
            @click="handleSelect(entity, index)"
          >
            <div v-if="entity">
              <span
                class="highlighted-type"
                :style="{
                  borderBottomColor: getGreenToRed(entity.confidence),
                  borderBottomWidth: '6px',
                  borderBottomStyle: 'solid'
                }"
                >{{ formatInd(index) }}</span
              >
              <p>{{ entity.value }}</p>
            </div>
            <div v-else>
              <span class="highlighted-type">{{ formatInd(index) }}</span>
            </div>
          </div>
        </div>
      </div>
      <button
        class="fabric-btn fabric-btn-submit"
        @click="handlePopulateReport"
      >
        Populate Pension Report
      </button>
    </div>
    <div v-else>
      <p>No text available for this page.</p>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import MLTextEdit from "@/components/ml-operations/MLTextEdit";
export default {
  name: "MLText",
  components: {
    MLTextEdit
  },
  computed: {
    ...mapGetters([
      "getApplicationExtractedText",
      "getExtractedTextContent",
      "getMLSelectedTextEntities",
      "getMLSelectedTextEntitiesIndex",
      "getEmptyValueArray",
      "getMLTextEntities",
      "getApplicationUuid",
      "getTracedPension",
      "getTracedPensionUuid",
      "getMLTextUuid"
    ]),
    getMLTextEntitiesLength() {
      if (!this.getMLTextEntities) return;
      return Object.keys(this.getMLTextEntities).length;
    },
    selectedEntity() {
      if (!this.getMLSelectedTextEntitiesIndex) return;
      return this.getMLSelectedTextEntitiesIndex;
    },
    combinedMLText() {
      return Object.assign(this.getMLTextEntities, this.getEmptyValueArray);
    }
  },
  methods: {
    ...mapMutations([
      "setMLTextEntities",
      "setEmptyValueArray",
      "setMLSelectedTextEntities"
    ]),
    ...mapActions(["updateEntity", "populateReport"]),
    handlePopulateReport() {
      this.$router.push({
        name: "Aggregation Form",
        params: {
          uuid: this.$route.params.auuid,
          tpuuid: this.$route.params.tpuuid
        }
      });
    },
    selectedStyle(entity) {
      return {
        "-focused": this.selectedEntity == entity
      };
    },
    handleClose() {
      this.setMLSelectedTextEntities({
        entity: null,
        ind: null
      });
    },
    async handleUpdate(params) {
      let mlObj = {};
      if (params.selectedEntity == "") {
        mlObj.uuid = this.getApplicationExtractedText[0].uuid;
        mlObj.mlEntities = this.getEmptyValueArray;
      } else {
        mlObj.uuid = this.$route.params.euuid;
        mlObj.mlEntities = this.getMLTextEntities;
      }

      for (let i in mlObj.mlEntities) {
        if (i == this.getMLSelectedTextEntitiesIndex) {
          if (typeof mlObj.mlEntities[i] === "string") {
            mlObj.mlEntities[i] = {
              type: null,
              value: params.value,
              page: null,
              confidence: null,
              geometry: {
                boundingBox: {
                  width: null,
                  height: null,
                  left: null,
                  top: null
                },
                polygon: [
                  {
                    x: null,
                    y: null
                  },
                  {
                    x: null,
                    y: null
                  },
                  {
                    x: null,
                    y: null
                  },
                  {
                    x: null,
                    y: null
                  }
                ]
              }
            };
          } else {
            mlObj.mlEntities[i].value = params.value;
          }
        }
      }

      if (params.selectedEntity == "") {
        this.setEmptyValueArray(mlObj.mlEntities);
      } else {
        this.setMLTextEntities(mlObj.mlEntities);
      }

      let data = {};
      data.uuid = mlObj.uuid;
      data.mlEntities = [];
      data.mlEntities.push(mlObj.mlEntities);

      this.updateEntity(data).catch(err => console.log(err));
    },
    handleSelect(entity, ind) {
      let ent = entity || {};
      this.setMLSelectedTextEntities({ ent, ind });
    }
  }
};
</script>
<style lang="scss" scoped>
.extracted-text {
  &-wrap {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  &-row {
    display: inline-block;
  }
}
.highlighted {
  &-type {
    display: block;
    position: relative;
    top: calc($spacingBase / 3);
    font-size: 10px;
    font-weight: bold;
  }
  &-text {
    display: inline-block;
    position: relative;
    border: 1px solid $grey;
    border-radius: 3px;
    padding: calc($spacingBase / 2);
    cursor: pointer;
    min-width: 120px;
    margin-right: 10px;
    margin-bottom: 10px;

    p {
      margin: 0;
      margin-top: 10px;
    }

    &.-focused,
    &:hover {
      background-color: $black;
      border: 1px solid $black;
      color: $white;
    }
  }
}
</style>
